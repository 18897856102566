import { render, staticRenderFns } from "./default.vue?vue&type=template&id=05426399&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=05426399&prod&lang=sass&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseFooter: require('/tmp/build_63b4a9ca/components/core/BaseFooter.vue').default,CookieConsent: require('/tmp/build_63b4a9ca/components/core/CookieConsent.vue').default})
