
import { mapState } from 'vuex'

export default {
    name: 'Footer',

    props: {
        bgColor: {
            type: String,
            default: 'primary',
        },
    },

    computed: {
        ...mapState('material', ['secoes']),
    },
}
