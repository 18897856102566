export const state = () => ({
    alert: false,
    alertMessage: '',
})

export const mutations = {
    setAlert(state, value) {
        state.alert = value
    },

    setAlertMessage(state, message) {
        state.alertMessage = message
    },
}
