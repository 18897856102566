// import materiais from '~/assets/materiais.json'

export const state = () => ({
    secoes: [],

    materiais: [],

    loading: false,
    loadingSecoes: false,
})

export const getters = {
    secaoRota: (state) => (secao) => {
        return state.secoes.find((item) => secao === item.rota)
    },
}

export const mutations = {
    setMateriais: (state, materiais) => (state.materiais = materiais),

    setLoading: (state, value) => (state.loading = value),

    setSecoes: (state, secoes) => (state.secoes = secoes),

    setLoadingSecoes: (state, value) => (state.loadingSecoes = value),
}

export const actions = {
    async getSecoes({ commit }) {
        commit('setLoadingSecoes', true)
        try {
            const response = await this.$axios.$get('materiais/secoes')
            commit('setSecoes', response)
        } catch (e) {
            commit('alert/setAlert', true, { root: true })
            commit(
                'alert/setAlertMessage',
                'Ocorreu um erro ao buscar as seções',
                {
                    root: true,
                }
            )
        } finally {
            commit('setLoadingSecoes', false)
        }
    },

    async getMateriais({ commit }, { secao, tag }) {
        commit('setMateriais', [])
        commit('setLoading', true)
        try {
            const response = await this.$axios.$get('materiais', {
                params: {
                    secao: secao ?? undefined,
                    tags: tag ?? undefined,
                },
            })
            commit('setMateriais', response)
        } catch (e) {
            commit('alert/setAlert', true, { root: true })
            commit(
                'alert/setAlertMessage',
                'Ocorreu um erro ao buscar os materiais',
                {
                    root: true,
                }
            )
        } finally {
            commit('setLoading', false)
        }
    },
}
