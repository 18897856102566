export const ServicoDetalhesAtributo = () => import('../../components/servicos/detalhes/ServicoDetalhesAtributo.vue' /* webpackChunkName: "components/servico-detalhes-atributo" */).then(c => wrapFunctional(c.default || c))
export const ServicoDetalhesAvaliacao = () => import('../../components/servicos/detalhes/ServicoDetalhesAvaliacao.vue' /* webpackChunkName: "components/servico-detalhes-avaliacao" */).then(c => wrapFunctional(c.default || c))
export const Alert = () => import('../../components/core/Alert.vue' /* webpackChunkName: "components/alert" */).then(c => wrapFunctional(c.default || c))
export const BaseFooter = () => import('../../components/core/BaseFooter.vue' /* webpackChunkName: "components/base-footer" */).then(c => wrapFunctional(c.default || c))
export const CardAtalho = () => import('../../components/core/CardAtalho.vue' /* webpackChunkName: "components/card-atalho" */).then(c => wrapFunctional(c.default || c))
export const CardAtalhoVertical = () => import('../../components/core/CardAtalhoVertical.vue' /* webpackChunkName: "components/card-atalho-vertical" */).then(c => wrapFunctional(c.default || c))
export const CardsAtalhosHome = () => import('../../components/core/CardsAtalhosHome.vue' /* webpackChunkName: "components/cards-atalhos-home" */).then(c => wrapFunctional(c.default || c))
export const CardsAtalhosInterna = () => import('../../components/core/CardsAtalhosInterna.vue' /* webpackChunkName: "components/cards-atalhos-interna" */).then(c => wrapFunctional(c.default || c))
export const ConteudoDetalheFooter = () => import('../../components/core/ConteudoDetalheFooter.vue' /* webpackChunkName: "components/conteudo-detalhe-footer" */).then(c => wrapFunctional(c.default || c))
export const CookieConsent = () => import('../../components/core/CookieConsent.vue' /* webpackChunkName: "components/cookie-consent" */).then(c => wrapFunctional(c.default || c))
export const NavDrawer = () => import('../../components/core/NavDrawer.vue' /* webpackChunkName: "components/nav-drawer" */).then(c => wrapFunctional(c.default || c))
export const PrincipalAtalhos = () => import('../../components/core/PrincipalAtalhos.vue' /* webpackChunkName: "components/principal-atalhos" */).then(c => wrapFunctional(c.default || c))
export const ShareButton = () => import('../../components/core/ShareButton.vue' /* webpackChunkName: "components/share-button" */).then(c => wrapFunctional(c.default || c))
export const InformacaoLista = () => import('../../components/informacao/InformacaoLista.vue' /* webpackChunkName: "components/informacao-lista" */).then(c => wrapFunctional(c.default || c))
export const GuiasCartilhasLista = () => import('../../components/materiais/GuiasCartilhasLista.vue' /* webpackChunkName: "components/guias-cartilhas-lista" */).then(c => wrapFunctional(c.default || c))
export const MateriaisListaBlog = () => import('../../components/materiais/MateriaisListaBlog.vue' /* webpackChunkName: "components/materiais-lista-blog" */).then(c => wrapFunctional(c.default || c))
export const MateriaisListaCard = () => import('../../components/materiais/MateriaisListaCard.vue' /* webpackChunkName: "components/materiais-lista-card" */).then(c => wrapFunctional(c.default || c))
export const EncontreApoio = () => import('../../components/servicos/EncontreApoio.vue' /* webpackChunkName: "components/encontre-apoio" */).then(c => wrapFunctional(c.default || c))
export const ServicoBusca = () => import('../../components/servicos/ServicoBusca.vue' /* webpackChunkName: "components/servico-busca" */).then(c => wrapFunctional(c.default || c))
export const ServicoBuscaDetalhada = () => import('../../components/servicos/ServicoBuscaDetalhada.vue' /* webpackChunkName: "components/servico-busca-detalhada" */).then(c => wrapFunctional(c.default || c))
export const ServicoFiltrosAtivos = () => import('../../components/servicos/ServicoFiltrosAtivos.vue' /* webpackChunkName: "components/servico-filtros-ativos" */).then(c => wrapFunctional(c.default || c))
export const ServicoLista = () => import('../../components/servicos/ServicoLista.vue' /* webpackChunkName: "components/servico-lista" */).then(c => wrapFunctional(c.default || c))
export const ServicoResultados = () => import('../../components/servicos/ServicoResultados.vue' /* webpackChunkName: "components/servico-resultados" */).then(c => wrapFunctional(c.default || c))
export const IconApps = () => import('../../components/svg/IconApps.vue' /* webpackChunkName: "components/icon-apps" */).then(c => wrapFunctional(c.default || c))
export const IconBubble = () => import('../../components/svg/IconBubble.vue' /* webpackChunkName: "components/icon-bubble" */).then(c => wrapFunctional(c.default || c))
export const IconInfo = () => import('../../components/svg/IconInfo.vue' /* webpackChunkName: "components/icon-info" */).then(c => wrapFunctional(c.default || c))
export const IconPhone = () => import('../../components/svg/IconPhone.vue' /* webpackChunkName: "components/icon-phone" */).then(c => wrapFunctional(c.default || c))
export const IconPin = () => import('../../components/svg/IconPin.vue' /* webpackChunkName: "components/icon-pin" */).then(c => wrapFunctional(c.default || c))
export const IconWhatsapp = () => import('../../components/svg/IconWhatsapp.vue' /* webpackChunkName: "components/icon-whatsapp" */).then(c => wrapFunctional(c.default || c))
export const IconWifi = () => import('../../components/svg/IconWifi.vue' /* webpackChunkName: "components/icon-wifi" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/svg/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
