// import materiais from '~/assets/materiais.json'

export const state = () => ({
    noticias: [],

    loading: false,
})

export const mutations = {
    setNoticias: (state, noticias) => (state.noticias = noticias),

    setLoading: (state, value) => (state.loading = value),
}

export const actions = {
    async getNoticias({ commit }, { tag }) {
        commit('setNoticias', [])
        commit('setLoading', true)
        try {
            const response = await this.$axios.$get('noticias', {
                params: {
                    tags: tag ?? undefined,
                },
            })
            commit('setNoticias', response)
        } catch (e) {
            commit('alert/setAlert', true, { root: true })
            commit(
                'alert/setAlertMessage',
                'Ocorreu um erro ao buscar as notícias',
                {
                    root: true,
                }
            )
        } finally {
            commit('setLoading', false)
        }
    },
}
