
export default {
    name: 'CookieConsent',

    data: () => ({
        alert: false,
    }),

    created() {
        if (!this.getGDPR() === true) {
            this.alert = true
        }
    },

    methods: {
        getGDPR() {
            if (process.browser) {
                return localStorage.getItem('GDPR:accepted', true)
            }
        },

        accept() {
            if (process.browser) {
                this.alert = false
                localStorage.setItem('GDPR:accepted', true)
                this.$gtm.init()
            }
        },

        /* deny() {
            if (process.browser) {
                this.alert = false
                localStorage.setItem('GDPR:accepted', false)
            }
        }, */
    },
}
