export const state = () => ({
    informacoes: [],
    count: 0,
    loading: false,

    tiposInformacoes: [],
})

export const mutations = {
    setInformacoes(state, informacoes) {
        state.informacoes = informacoes.results
        state.count = informacoes.count
    },

    clearInformacoes(state) {
        state.informacoes = []
        state.count = 0
    },

    setLoading(state, value) {
        state.loading = value
    },

    setTipos(state, value) {
        state.tiposInformacoes = value
    },
}

export const actions = {
    async getInformacoes({ commit }, filtros) {
        commit('clearInformacoes')
        commit('setLoading', true)
        try {
            const response = await this.$axios.$get('informacoes', {
                params: filtros,
            })
            commit('setInformacoes', response)
        } catch (e) {
            commit('alert/setAlert', true, { root: true })
            commit('alert/setAlertMessage', 'Ocorreu um erro ao buscar as informações', {
                root: true,
            })
        } finally {
            commit('setLoading', false)
        }
    },

    async getTipos({ commit }) {
        commit('setTipos', [])
        try {
            const response = await this.$axios.$get('informacoes/tipos')
            commit('setTipos', response)
        } catch (e) {
            commit('alert/setAlert', true, { root: true })
            commit('alert/setAlertMessage', 'Ocorreu um erro ao buscar os tipos de serviços', {
                root: true,
            })
        }
    },
}
