export default async function ({ $axios, route, redirect, error }) {
    const param = route.params.rota
    if (!isNaN(param) && !isNaN(parseFloat(param))) {
        // Se parametro da rota for numerico, então é um id
        const { rota } = await $axios.$get('apoio/id/' + param)
        if (rota) {
            redirect(301, { name: 'ajuda-rota', params: { rota } })
        } else {
            error(404, 'Serviço não encontrado')
        }
    }
}
