import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5d3584d6 = () => interopDefault(import('../pages/direitos/index.vue' /* webpackChunkName: "pages/direitos/index" */))
const _e82c2ec2 = () => interopDefault(import('../pages/indique/index.vue' /* webpackChunkName: "pages/indique/index" */))
const _d1ccc1c8 = () => interopDefault(import('../pages/noticias/index.vue' /* webpackChunkName: "pages/noticias/index" */))
const _1714af54 = () => interopDefault(import('../pages/plataforma.vue' /* webpackChunkName: "pages/plataforma" */))
const _d440c796 = () => interopDefault(import('../pages/ajuda/todos-os-estados.vue' /* webpackChunkName: "pages/ajuda/todos-os-estados" */))
const _c514b9a0 = () => interopDefault(import('../pages/direitos/arquivo.vue' /* webpackChunkName: "pages/direitos/arquivo" */))
const _e67094c6 = () => interopDefault(import('../pages/indique/enviado.vue' /* webpackChunkName: "pages/indique/enviado" */))
const _54b85652 = () => interopDefault(import('../pages/ajuda/canal/whatsapp.vue' /* webpackChunkName: "pages/ajuda/canal/whatsapp" */))
const _321bc1d8 = () => interopDefault(import('../pages/ajuda/categoria/acolhimento.vue' /* webpackChunkName: "pages/ajuda/categoria/acolhimento" */))
const _77b9eaa2 = () => interopDefault(import('../pages/ajuda/categoria/denuncia.vue' /* webpackChunkName: "pages/ajuda/categoria/denuncia" */))
const _117d4100 = () => interopDefault(import('../pages/ajuda/categoria/juridico.vue' /* webpackChunkName: "pages/ajuda/categoria/juridico" */))
const _2e3fdf06 = () => interopDefault(import('../pages/ajuda/categoria/psicologico.vue' /* webpackChunkName: "pages/ajuda/categoria/psicologico" */))
const _7d85266b = () => interopDefault(import('../pages/ajuda/categoria/saude.vue' /* webpackChunkName: "pages/ajuda/categoria/saude" */))
const _3adfb0e2 = () => interopDefault(import('../pages/ajuda/centro-oeste/distrito-federal-df.vue' /* webpackChunkName: "pages/ajuda/centro-oeste/distrito-federal-df" */))
const _5ec166ac = () => interopDefault(import('../pages/ajuda/centro-oeste/goias-go.vue' /* webpackChunkName: "pages/ajuda/centro-oeste/goias-go" */))
const _c4f39722 = () => interopDefault(import('../pages/ajuda/centro-oeste/mato-grosso-do-sul-ms.vue' /* webpackChunkName: "pages/ajuda/centro-oeste/mato-grosso-do-sul-ms" */))
const _5abf5a73 = () => interopDefault(import('../pages/ajuda/centro-oeste/mato-grosso-mt.vue' /* webpackChunkName: "pages/ajuda/centro-oeste/mato-grosso-mt" */))
const _48fce63d = () => interopDefault(import('../pages/ajuda/nacional/brasil-br.vue' /* webpackChunkName: "pages/ajuda/nacional/brasil-br" */))
const _49db7564 = () => interopDefault(import('../pages/ajuda/nordeste/alagoas-al.vue' /* webpackChunkName: "pages/ajuda/nordeste/alagoas-al" */))
const _4af243b2 = () => interopDefault(import('../pages/ajuda/nordeste/bahia-ba.vue' /* webpackChunkName: "pages/ajuda/nordeste/bahia-ba" */))
const _7fc35746 = () => interopDefault(import('../pages/ajuda/nordeste/ceara-ce.vue' /* webpackChunkName: "pages/ajuda/nordeste/ceara-ce" */))
const _544feeac = () => interopDefault(import('../pages/ajuda/nordeste/maranhao-ma.vue' /* webpackChunkName: "pages/ajuda/nordeste/maranhao-ma" */))
const _1d29a05a = () => interopDefault(import('../pages/ajuda/nordeste/paraiba-pb.vue' /* webpackChunkName: "pages/ajuda/nordeste/paraiba-pb" */))
const _6025be8c = () => interopDefault(import('../pages/ajuda/nordeste/pernambuco-pe.vue' /* webpackChunkName: "pages/ajuda/nordeste/pernambuco-pe" */))
const _ec16d174 = () => interopDefault(import('../pages/ajuda/nordeste/piaui-pi.vue' /* webpackChunkName: "pages/ajuda/nordeste/piaui-pi" */))
const _5107ba74 = () => interopDefault(import('../pages/ajuda/nordeste/rio-grande-do-norte-rn.vue' /* webpackChunkName: "pages/ajuda/nordeste/rio-grande-do-norte-rn" */))
const _e20f25b8 = () => interopDefault(import('../pages/ajuda/nordeste/sergipe-se.vue' /* webpackChunkName: "pages/ajuda/nordeste/sergipe-se" */))
const _7d46c7ec = () => interopDefault(import('../pages/ajuda/norte/acre-ac.vue' /* webpackChunkName: "pages/ajuda/norte/acre-ac" */))
const _7da23094 = () => interopDefault(import('../pages/ajuda/norte/amapa-ap.vue' /* webpackChunkName: "pages/ajuda/norte/amapa-ap" */))
const _73aa9f75 = () => interopDefault(import('../pages/ajuda/norte/amazonas-am.vue' /* webpackChunkName: "pages/ajuda/norte/amazonas-am" */))
const _6fdba460 = () => interopDefault(import('../pages/ajuda/norte/para-pa.vue' /* webpackChunkName: "pages/ajuda/norte/para-pa" */))
const _13c84152 = () => interopDefault(import('../pages/ajuda/norte/rondonia-ro.vue' /* webpackChunkName: "pages/ajuda/norte/rondonia-ro" */))
const _da556ccc = () => interopDefault(import('../pages/ajuda/norte/roraima-rr.vue' /* webpackChunkName: "pages/ajuda/norte/roraima-rr" */))
const _52123f92 = () => interopDefault(import('../pages/ajuda/norte/tocantins-to.vue' /* webpackChunkName: "pages/ajuda/norte/tocantins-to" */))
const _4b35ea45 = () => interopDefault(import('../pages/ajuda/sudeste/espirito-santo-es.vue' /* webpackChunkName: "pages/ajuda/sudeste/espirito-santo-es" */))
const _3ed10a18 = () => interopDefault(import('../pages/ajuda/sudeste/minas-gerais-mg.vue' /* webpackChunkName: "pages/ajuda/sudeste/minas-gerais-mg" */))
const _f508ce96 = () => interopDefault(import('../pages/ajuda/sudeste/rio-de-janeiro-rj.vue' /* webpackChunkName: "pages/ajuda/sudeste/rio-de-janeiro-rj" */))
const _c979fde4 = () => interopDefault(import('../pages/ajuda/sudeste/sao-paulo-sp.vue' /* webpackChunkName: "pages/ajuda/sudeste/sao-paulo-sp" */))
const _42ddaed6 = () => interopDefault(import('../pages/ajuda/sul/parana-pr.vue' /* webpackChunkName: "pages/ajuda/sul/parana-pr" */))
const _61d87f46 = () => interopDefault(import('../pages/ajuda/sul/rio-grande-do-sul-rs.vue' /* webpackChunkName: "pages/ajuda/sul/rio-grande-do-sul-rs" */))
const _25e12e02 = () => interopDefault(import('../pages/ajuda/sul/santa-catarina-sc.vue' /* webpackChunkName: "pages/ajuda/sul/santa-catarina-sc" */))
const _a54c5d22 = () => interopDefault(import('../pages/direitos/informacoes/_rota.vue' /* webpackChunkName: "pages/direitos/informacoes/_rota" */))
const _f2b9efb0 = () => interopDefault(import('../pages/ajuda/_rota.vue' /* webpackChunkName: "pages/ajuda/_rota" */))
const _449640d0 = () => interopDefault(import('../pages/direitos/_secao/index.vue' /* webpackChunkName: "pages/direitos/_secao/index" */))
const _d53a0458 = () => interopDefault(import('../pages/noticias/_slug.vue' /* webpackChunkName: "pages/noticias/_slug" */))
const _42df9f88 = () => interopDefault(import('../pages/direitos/_secao/_slug.vue' /* webpackChunkName: "pages/direitos/_secao/_slug" */))
const _6b5c0f97 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/direitos-das-mulheres",
    component: _5d3584d6,
    name: "direitos"
  }, {
    path: "/indique-um-servico-contra-violencia-domestica",
    component: _e82c2ec2,
    name: "indique"
  }, {
    path: "/noticias",
    component: _d1ccc1c8,
    name: "noticias"
  }, {
    path: "/sobre-plataforma-mulher-segura-unfpa",
    component: _1714af54,
    name: "plataforma"
  }, {
    path: "/preciso-de-ajuda/todos-os-estados",
    component: _d440c796,
    name: "ajuda-todos-os-estados"
  }, {
    path: "/direitos-das-mulheres/arquivo",
    component: _c514b9a0,
    name: "direitos-arquivo"
  }, {
    path: "/indique-um-servico-contra-violencia-domestica/enviado",
    component: _e67094c6,
    name: "indique-enviado"
  }, {
    path: "/preciso-de-ajuda/canal/denuncia-violencia-contra-mulher-whatsapp-mobile",
    component: _54b85652,
    name: "ajuda-canal-whatsapp"
  }, {
    path: "/preciso-de-ajuda/categoria/violencia-contra-a-mulher-acolhimento-vitimas",
    component: _321bc1d8,
    name: "ajuda-categoria-acolhimento"
  }, {
    path: "/preciso-de-ajuda/categoria/violencia-contra-a-mulher-canal-de-denuncia",
    component: _77b9eaa2,
    name: "ajuda-categoria-denuncia"
  }, {
    path: "/preciso-de-ajuda/categoria/violencia-domestica-orientacao-juridica",
    component: _117d4100,
    name: "ajuda-categoria-juridico"
  }, {
    path: "/preciso-de-ajuda/categoria/mulheres-apoio-psicologico",
    component: _2e3fdf06,
    name: "ajuda-categoria-psicologico"
  }, {
    path: "/preciso-de-ajuda/categoria/saude-da-mulher-direitos-sexuais-reprodutivos",
    component: _7d85266b,
    name: "ajuda-categoria-saude"
  }, {
    path: "/preciso-de-ajuda/centro-oeste/distrito-federal-df",
    component: _3adfb0e2,
    name: "ajuda-centro-oeste-distrito-federal-df"
  }, {
    path: "/preciso-de-ajuda/centro-oeste/goias-go",
    component: _5ec166ac,
    name: "ajuda-centro-oeste-goias-go"
  }, {
    path: "/preciso-de-ajuda/centro-oeste/mato-grosso-do-sul-ms",
    component: _c4f39722,
    name: "ajuda-centro-oeste-mato-grosso-do-sul-ms"
  }, {
    path: "/preciso-de-ajuda/centro-oeste/mato-grosso-mt",
    component: _5abf5a73,
    name: "ajuda-centro-oeste-mato-grosso-mt"
  }, {
    path: "/preciso-de-ajuda/nacional/brasil-br",
    component: _48fce63d,
    name: "ajuda-nacional-brasil-br"
  }, {
    path: "/preciso-de-ajuda/nordeste/alagoas-al",
    component: _49db7564,
    name: "ajuda-nordeste-alagoas-al"
  }, {
    path: "/preciso-de-ajuda/nordeste/bahia-ba",
    component: _4af243b2,
    name: "ajuda-nordeste-bahia-ba"
  }, {
    path: "/preciso-de-ajuda/nordeste/ceara-ce",
    component: _7fc35746,
    name: "ajuda-nordeste-ceara-ce"
  }, {
    path: "/preciso-de-ajuda/nordeste/maranhao-ma",
    component: _544feeac,
    name: "ajuda-nordeste-maranhao-ma"
  }, {
    path: "/preciso-de-ajuda/nordeste/paraiba-pb",
    component: _1d29a05a,
    name: "ajuda-nordeste-paraiba-pb"
  }, {
    path: "/preciso-de-ajuda/nordeste/pernambuco-pe",
    component: _6025be8c,
    name: "ajuda-nordeste-pernambuco-pe"
  }, {
    path: "/preciso-de-ajuda/nordeste/piaui-pi",
    component: _ec16d174,
    name: "ajuda-nordeste-piaui-pi"
  }, {
    path: "/preciso-de-ajuda/nordeste/rio-grande-do-norte-rn",
    component: _5107ba74,
    name: "ajuda-nordeste-rio-grande-do-norte-rn"
  }, {
    path: "/preciso-de-ajuda/nordeste/sergipe-se",
    component: _e20f25b8,
    name: "ajuda-nordeste-sergipe-se"
  }, {
    path: "/preciso-de-ajuda/norte/acre-ac",
    component: _7d46c7ec,
    name: "ajuda-norte-acre-ac"
  }, {
    path: "/preciso-de-ajuda/norte/amapa-ap",
    component: _7da23094,
    name: "ajuda-norte-amapa-ap"
  }, {
    path: "/preciso-de-ajuda/norte/amazonas-am",
    component: _73aa9f75,
    name: "ajuda-norte-amazonas-am"
  }, {
    path: "/preciso-de-ajuda/norte/para-pa",
    component: _6fdba460,
    name: "ajuda-norte-para-pa"
  }, {
    path: "/preciso-de-ajuda/norte/rondonia-ro",
    component: _13c84152,
    name: "ajuda-norte-rondonia-ro"
  }, {
    path: "/preciso-de-ajuda/norte/roraima-rr",
    component: _da556ccc,
    name: "ajuda-norte-roraima-rr"
  }, {
    path: "/preciso-de-ajuda/norte/tocantins-to",
    component: _52123f92,
    name: "ajuda-norte-tocantins-to"
  }, {
    path: "/preciso-de-ajuda/sudeste/espirito-santo-es",
    component: _4b35ea45,
    name: "ajuda-sudeste-espirito-santo-es"
  }, {
    path: "/preciso-de-ajuda/sudeste/minas-gerais-mg",
    component: _3ed10a18,
    name: "ajuda-sudeste-minas-gerais-mg"
  }, {
    path: "/preciso-de-ajuda/sudeste/rio-de-janeiro-rj",
    component: _f508ce96,
    name: "ajuda-sudeste-rio-de-janeiro-rj"
  }, {
    path: "/preciso-de-ajuda/sudeste/sao-paulo-sp",
    component: _c979fde4,
    name: "ajuda-sudeste-sao-paulo-sp"
  }, {
    path: "/preciso-de-ajuda/sul/parana-pr",
    component: _42ddaed6,
    name: "ajuda-sul-parana-pr"
  }, {
    path: "/preciso-de-ajuda/sul/rio-grande-do-sul-rs",
    component: _61d87f46,
    name: "ajuda-sul-rio-grande-do-sul-rs"
  }, {
    path: "/preciso-de-ajuda/sul/santa-catarina-sc",
    component: _25e12e02,
    name: "ajuda-sul-santa-catarina-sc"
  }, {
    path: "/direitos-das-mulheres/informacoes/:rota?",
    component: _a54c5d22,
    name: "direitos-informacoes-rota"
  }, {
    path: "/preciso-de-ajuda/:rota?",
    component: _f2b9efb0,
    name: "ajuda-rota"
  }, {
    path: "/direitos-das-mulheres/:secao",
    component: _449640d0,
    name: "direitos-secao"
  }, {
    path: "/noticias/:slug",
    component: _d53a0458,
    name: "noticias-slug"
  }, {
    path: "/direitos-das-mulheres/:secao/:slug",
    component: _42df9f88,
    name: "direitos-secao-slug"
  }, {
    path: "/",
    component: _6b5c0f97,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
