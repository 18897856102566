
import LazyHydrate from 'vue-lazy-hydration'
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'Layout',

    components: { LazyHydrate },

    data: () => ({
        drawer: false,
        extendToolbar: true,
    }),

    computed: {
        ...mapState('material', ['secoes']),
        ...mapGetters('material', ['secaoRota']),

        // isMobile() {
        //     console.log(this.$vuetify.breakpoint.mobile)
        //     return this.$device.isMobile || this.$vuetify.breakpoint.mobile
        // },

        isHomePage() {
            return this.$route.name === 'index'
        },

        // isDetailPage() {
        //     return this.$route.name === 'ajuda-rota'
        // },

        // isIndiquePage() {
        //     return this.$route.name?.includes('indique')
        // },

        // titulo() {
        //     switch (this.$route.name) {
        //         case 'index':
        //             return 'Encontre ajuda'
        //         case 'ajuda-rota':
        //             return 'serviço'
        //         case 'ajuda-todos-os-estados':
        //             return 'todos os estados'
        //         case 'plataforma':
        //             return 'a plataforma'
        //         case 'indique':
        //         case 'indique-enviado':
        //             return 'indique um serviço'
        //         case 'direitos':
        //             return 'conheça seus direitos'
        //         case 'noticias':
        //         case 'noticias-slug':
        //             return 'notícias'
        //     }

        //     if (this.$route.name?.includes('direitos-secao')) {
        //         return this.secaoRota(this.$route.params.secao).nome
        //     } else if (this.$route.path?.includes('/categoria') || this.$route.path?.includes('/canal')) {
        //         return this.getNomeAtalho()
        //     } else {
        //         return this.getNomeEstado()
        //     }
        // },

        gradientClass() {
            if (this.$route.name?.includes('direitos')) {
                return 'gradient-direitos'
            }
            if (this.$route.name?.includes('noticias')) {
                return 'gradient-plataforma'
            }

            switch (this.$route.name) {
                case 'plataforma':
                case 'indique':
                case 'indique-enviado':
                    return 'gradient-' + this.$route.name
                default:
                    return 'default-gradient'
            }
        },

        bgColor() {
            if (this.$route.name?.includes('direitos')) {
                return 'success'
            }
            if (this.$route.name?.includes('noticias')) {
                return 'secondary'
            }

            switch (this.$route.name) {
                case 'plataforma':
                    return 'secondary'
                case 'indique':
                case 'indique-enviado':
                    return 'accent'
                default:
                    return 'primary'
            }
        },

        // textColor() {
        //     return `${this.bgColor}--text`
        // },
    },

    // beforeMount() {
    //     window.addEventListener('scroll', this.handleScroll)
    // },

    // beforeDestroy() {
    //     window.removeEventListener('scroll', this.handleScroll)
    // },

    // methods: {
    //     handleScroll(e) {
    //         if (window.scrollY === 0) {
    //             this.extendToolbar = true
    //         } else {
    //             this.extendToolbar = false
    //         }
    //     },

    //     scrollToTop() {
    //         this.$vuetify.goTo(0, { easing: 'easeInOutCubic' })
    //     },

    //     getNomeEstado() {
    //         const subpaths = this.$route.path.split('/')
    //         let estado = subpaths[subpaths.length - 1]
    //         estado = estado.split('-')
    //         delete estado[estado.length - 1]
    //         estado = estado.join(' ')
    //         estado = estado.replace(/(^\w|\s\w)/g, (char) => char.toUpperCase())
    //         return estado
    //     },

    //     getNomeAtalho() {
    //         const routeParts = this.$route.name.split('-')
    //         const routeName = routeParts[routeParts.length - 1]

    //         switch (routeName) {
    //             case 'apps':
    //                 return 'aplicativos'
    //             case 'whatsapp':
    //                 return 'WhatsApp'
    //             case 'denuncia':
    //                 return 'denúncia'
    //             case 'juridico':
    //                 return 'jurídico'
    //             case 'psicologico':
    //                 return 'psicológico'
    //             case 'saude':
    //                 return 'saúde'
    //             default:
    //                 return routeName
    //         }
    //     },
    // },
}
