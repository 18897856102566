export const state = () => ({
    servicos: [],
    count: 0,
    loading: false,

    tiposServicos: [],

    estados: {
        ac: { nome: 'Acre', rota: '/norte/acre-ac' },
        al: { nome: 'Alagoas', rota: '/nordeste/alagoas-al' },
        ap: { nome: 'Amapá', rota: '/norte/amapa-ap' },
        am: { nome: 'Amazonas', rota: '/norte/amazonas-am' },
        ba: { nome: 'Bahia', rota: '/nordeste/bahia-ba' },
        ce: { nome: 'Ceará', rota: '/nordeste/ceara-ce' },
        df: {
            nome: 'Distrito Federal',
            rota: '/centro-oeste/distrito-federal-df',
        },
        es: { nome: 'Espírito Santo', rota: '/sudeste/espirito-santo-es' },
        go: { nome: 'Goiás', rota: '/centro-oeste/goias-go' },
        ma: { nome: 'Maranhão', rota: '/nordeste/maranhao-ma' },
        mt: { nome: 'Mato Grosso', rota: '/centro-oeste/mato-grosso-mt' },
        ms: {
            nome: 'Mato Grosso do Sul',
            rota: '/centro-oeste/mato-grosso-do-sul-ms',
        },
        mg: { nome: 'Minas Gerais', rota: '/sudeste/minas-gerais-mg' },
        pa: { nome: 'Pará', rota: '/norte/para-pa' },
        pb: { nome: 'Paraíba', rota: '/nordeste/paraiba-pb' },
        pr: { nome: 'Paraná', rota: '/sul/parana-pr' },
        pe: { nome: 'Pernambuco', rota: '/nordeste/pernambuco-pe' },
        pi: { nome: 'Piauí', rota: '/nordeste/piaui-pi' },
        rj: { nome: 'Rio de Janeiro', rota: '/sudeste/rio-de-janeiro-rj' },
        rn: {
            nome: 'Rio Grande do Norte',
            rota: '/nordeste/rio-grande-do-norte-rn',
        },
        rs: { nome: 'Rio Grande do Sul', rota: '/sul/rio-grande-do-sul-rs' },
        ro: { nome: 'Rondônia', rota: '/norte/rondonia-ro' },
        rr: { nome: 'Roraima', rota: '/norte/roraima-rr' },
        sc: { nome: 'Santa Catarina', rota: '/sul/santa-catarina-sc' },
        sp: { nome: 'São Paulo', rota: '/sudeste/sao-paulo-sp' },
        se: { nome: 'Sergipe', rota: '/nordeste/sergipe-se' },
        to: { nome: 'Tocantins', rota: '/norte/tocantins-to' },
    },
})

export const getters = {
    listaEstados(state) {
        const items = Object.keys(state.estados).map((uf) => ({
            value: uf,
            text: state.estados[uf].nome,
        }))
        items.unshift({ text: 'Nacional', value: 'nacional' })
        items.unshift({ text: 'Todos os estados', value: '' })
        return items
    },

    listaEstadosIndique(state) {
        const items = Object.keys(state.estados).map((uf) => ({
            value: uf,
            text: state.estados[uf].nome,
        }))
        items.unshift({ text: 'Nacional', value: '' })
        return items
    },
}

export const mutations = {
    setServicos(state, servicos) {
        state.servicos = servicos.results
        state.count = servicos.count
    },

    clearServicos(state) {
        state.servicos = []
        state.count = 0
    },

    setLoading(state, value) {
        state.loading = value
    },

    setTipos(state, value) {
        state.tiposServicos = value
    },
}

export const actions = {
    async getServicos({ commit }, filtros) {
        commit('clearServicos')
        commit('setLoading', true)
        try {
            const response = await this.$axios.$get('apoio', {
                params: filtros,
            })
            commit('setServicos', response)
        } catch (e) {
            commit('alert/setAlert', true, { root: true })
            commit('alert/setAlertMessage', 'Ocorreu um erro ao buscar os serviços', {
                root: true,
            })
        } finally {
            commit('setLoading', false)
        }
    },

    async getTipos({ commit }) {
        commit('setTipos', [])
        try {
            const response = await this.$axios.$get('apoio/tipos')
            commit('setTipos', response)
        } catch (e) {
            commit('alert/setAlert', true, { root: true })
            commit('alert/setAlertMessage', 'Ocorreu um erro ao buscar os tipos de serviços', {
                root: true,
            })
        }
    },
}
